import React from 'react';
import ReactDOM from 'react-dom/client';
import './Style/Style.css';
import { Interface } from './Interface';

const Root = ReactDOM.createRoot(
  document.getElementById('Root')
);

Root.render(
  <React.StrictMode>
    <Interface />
  </React.StrictMode>
);