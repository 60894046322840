import Logo from "../Style/assets/Logo.png";
const Interface = () => {

    return(
        <section className="Fms-Core" >
            <nav className="Fms-Nav" >
                <div className="Fms-Nav-Logo" >
                    <img width={25} src={Logo} alt="Logo" />
                </div>
                <div className="Fms-Nav-Actions" >
                    <button className="Fms-Nav-Action" >Request a Movie</button>
                    <button className="Fms-Nav-Action" >Rate Us.</button>
                    <a href="https://t.me/free_movies_space_bot" className="Fms-Nav-Action Fms-Nav-Action-Mob" >Community</a>
                </div>
            </nav>
            <section className="Fms-Section" >
                <header className="Fms-Header Fms-Header-Mob" >
                    <h1 className="Fms-Header-Title" >Free Movies Space</h1>
                    <h5 className="Fms-Header-Subtitle" >Watch Movies For Free & Earn Real Money.</h5>
                    <p className="Fms-Header-Description" >Free Movies Space is your go-to free movie streaming platform. Stream blockbuster movies and earn rewards like cash prizes for watching! All Movies for free available from Netflix , Prime , Hotstar & Rest..</p>
                    <ul className="Fms-Header-Bullets" >
                        <li className="Fms-Header-Bullet" >All Latest Movies & Series</li>
                        <li className="Fms-Header-Bullet" >Free Movies</li>
                        <li className="Fms-Header-Bullet" >Watch & Earn Money</li>
                        <li className="Fms-Header-Bullet" >Get 1000/Referral</li>
                    </ul>
                    <p className="Fms-Header-Footer" >डाउनलोड करने के लिए नीचे दिए गए बटन पर अभी क्लिक करके</p>
                    <div className="Fms-Header-Buttons" >
                        <a download href="https://free-movies.happymarketing.xyz/Meta/Preview.png" className="Fms-Header-Button"  style={ { backgroundColor:"#95ff00" } }>
                            <div className="Fms-Header-Button-Icon" >
                                <img width={30} src="https://icon-library.com/images/icon-download-png/icon-download-png-0.jpg" alt="Download Button Icon" />
                            </div>
                            <div className="Fms-Header-Button-Label" >
                                <p className="Fms-Header-Button-Label-Small" >Fast Link</p>
                                <p className="Fms-Header-Button-Label-Big" >Download</p>
                            </div>
                        </a>
                        {/* <button className="Fms-Header-Button" >
                            <div className="Fms-Header-Button-Icon" >
                                <img width={40} src="https://static.vecteezy.com/system/resources/previews/022/613/026/original/google-play-store-icon-logo-symbol-free-png.png" alt="Download Button Icon" />
                            </div>
                            <div className="Fms-Header-Button-Label" >
                                <p className="Fms-Header-Button-Label-Small" >Get it on</p>
                                <p className="Fms-Header-Button-Label-Big" >Playstore</p>
                            </div>
                        </button> */}
                    </div>
                </header>
            </section>
        </section>
    );

};

export { Interface };